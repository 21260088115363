@tailwind base;

@layer base {
  th,
  td {
    white-space: nowrap;
  }

  h1 {
    @apply text-2xl;
  }

  h2 {
    @apply text-xl;
  }

  h3 {
    @apply text-lg;
  }
}

@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Hellix';
  font-weight: 300;
  font-style: normal;
  src: url('./assets/fonts/Hellix-Light.woff');
}

@font-face {
  font-family: 'Hellix';
  font-weight: 400;
  font-style: normal;
  src: url('./assets/fonts/Hellix-Regular.woff');
}

@font-face {
  font-family: 'Hellix';
  font-weight: 500;
  font-style: normal;
  src: url('./assets/fonts/Hellix-Medium.woff');
}

@font-face {
  font-family: 'Hellix';
  font-weight: 600;
  font-style: normal;
  src: url('./assets/fonts/Hellix-SemiBold.woff');
}

@font-face {
  font-family: 'Hellix';
  font-weight: 700;
  font-style: normal;
  src: url('./assets/fonts/Hellix-Bold.woff');
}

@font-face {
  font-family: 'Hellix';
  font-weight: 800;
  font-style: normal;
  src: url('./assets/fonts/Hellix-Black.woff');
}

.modalScrollBar::-webkit-scrollbar {
  width: 5px;
}

.modalScrollBar::-webkit-scrollbar-track {
  background: #323333;
}

.modalScrollBar::-webkit-scrollbar-thumb {
  background: #747474;
  opacity: 0.6;
}

.tableScrollBar::-webkit-scrollbar {
  height: 5px;
}

.tableScrollBar::-webkit-scrollbar-track {
  background: #323333;
}

.tableScrollBar::-webkit-scrollbar-thumb {
  background: #747474;
  opacity: 0.6;
}

.titleEllipses {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1; /* for older versions of webkit browsers */
}
